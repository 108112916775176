const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const submarketMap = {
  "Midstream": ["NGL Fractionation", "Gas Processing"],
  "Terminals": ["Ethane","LPG","Refined Products"],
  "Chemicals & Refining": ["Petrochemicals","Refining","Syngas","Renewable Fuels","Specialty Chemicals"],
  "Energy Transition": ["Renewable Fuels","Ethanol","Hydrogen","Carbon Capture"],
  "Advanced Recycling": [],
  "Power & Industrial": ["Manufacturing","Pulp & Paper","Cogeneration","Simple & Combined Cycle"],
  "Marine": [],
  "Infrastructure": ["Transportation","Water","Federal"],
  "Federal": []
};

function restrictSubmarkets(market) {
  const submarkets = $$(`fieldset[name="submarket"] label`);
  const allSubmarketInput = $(`fieldset[name="submarket"] input[id="submarket-all"]`);
  const control = allSubmarketInput.parentNode.parentNode.parentNode;
  const controlButton = $("button", control);
  const buttonText = $(".button-text", controlButton);

  $(`fieldset[name="submarket"] input#submarket-all + span`).textContent = "All";
  allSubmarketInput.checked = true;
  buttonText.textContent = controlButton.getAttribute("data-field");
  submarkets.forEach((submarket, index) => {
    if (index !== 0) {
      submarket.classList.add("hidden");
    }
  })

  if (market === "on") {
    submarkets.forEach((submarket, index) => {
      if (index !== 0) {
        submarket.classList.remove("hidden");
      }
    })
  } else {
    const validSubmarkets = submarketMap[market];
    if (validSubmarkets.length === 0) {
      $(`fieldset[name="submarket"] input#submarket-all + span`).textContent = "None";
    } else {
      validSubmarkets.forEach((submarket) => {
        $(`fieldset[name="submarket"] input[value="${submarket}"]`).parentNode.classList.remove("hidden");
      })
    }
  }
}

function getParams (url = window.location) {
  let params = {};

  new URL(url).searchParams.forEach(function (val, key) {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]];
      }
      params[key].push(val);
    } else {
      params[key] = val;
    }
  });

  return params;
}

function scrollTo (element) {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop
  if (!element) {
    return;
  }

  let headerOffset = 0;
  if ($("body").classList.contains("samadmin")) {
    headerOffset = headerOffset + 97;
  }

  window.scroll({
    top: element.getBoundingClientRect()["top"] + scrollTop
          - headerOffset
          - 45 /* Extra padding for visual look */,
    behavior: 'smooth'
  });
};

class FilterList {
  constructor(component, options = {}) {
    const filterControls = $$(".filter-control fieldset", component);
    const filterButtons = $$(".filter-control > button", component);
    const searchControl = $(".filter-search");
    const loadMoreSection = $(".load-more", component.parentNode);
    const emptyMessage = $(".empty-message", component.parentNode);
    const items = $$("div[itemprop='itemListElement']", component.parentNode);
    const filters = $$(".filter-control input[type='radio']", component);
    const displayAmount = 6;
    let filterSelectors = [];
    let queryParams = getParams();

    filterControls.forEach(fieldset => {
      filterSelectors.push(`.filter-control input[name='${fieldset.name}']:checked`);

      //Sorts the filter options
      let items = fieldset.childNodes;
      let itemsArr = [];
      for (var i in items) {
          if (items[i].nodeType == 1) { // get rid of the whitespace text nodes
              itemsArr.push(items[i]);
          }
      }

      itemsArr.sort(function(a, b) {
        let aSpan = $("span", a);
        let bSpan = $("span", b);
        let aSpanText = aSpan.innerHTML.toLowerCase();
        let bSpanText = bSpan.innerHTML.toLowerCase();

        if(bSpanText === 'all') {
          return 1;
        } else if (aSpanText === 'all') {
          return -1;
        } else if (aSpanText == bSpanText) {
          return 0;
        } else if (aSpanText > bSpanText) {
          return 1;
        } else {
          return -1;
        }
      });

      for (let i = 0; i < itemsArr.length; ++i) {
        fieldset.appendChild(itemsArr[i]);
      }
    })

    this.displayAmount = displayAmount;
    this.searchControl = searchControl;
    this.filterSelectors = filterSelectors;
    this.loadMoreSection = loadMoreSection;
    this.emptyMessage = emptyMessage;
    this.items = items;
    this.filterControls = filterControls;
    this.filterButtons = filterButtons;
    this.revealItems = this.revealItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.openFilters = this.openFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.initLoadMore = this.initLoadMore.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.component = component;
    let filterItems = this.filterItems;
    let openFilters = this.openFilters;
    let closeFilters = this.closeFilters;

    filters.forEach(input => {
      input.addEventListener('change', () => {
        const control = input.parentNode.parentNode.parentNode;
        const fieldset = input.parentNode.parentNode
        const controlButton = $("button", control);
        const buttonText = $(".button-text", controlButton);

        if (input.value === "on") {
          buttonText.textContent = controlButton.getAttribute("data-field");
        } else {
          buttonText.textContent = input.value;
        }

        if (fieldset.getAttribute("name") === "market" && $(`fieldset[name="submarket"]`, component.parentNode)) {
          restrictSubmarkets(input.value);
        }

        this.filterItems();
      })
    })

    //Selects filters when query strings are passed
    let filterQuery = false;
    Object.keys(queryParams).forEach(param => {
      let radio = $(`.filter-control input[type='radio'][name='${param}'][value='${queryParams[param]}']`);
      if (radio) {
        let evt = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        });
        radio.dispatchEvent(evt);
        filterQuery = true;
      }
    })

    if (filterQuery) {
      scrollTo(component.parentNode);
    }

    //Close filter on outer click
    document.addEventListener('click', function (event) {
      let closest = event.target.closest(".filter-control");
      if (closest) return;

      closeFilters();
    }, false);

    document.addEventListener('click', function (event) {
      let closest = event.target.closest("button.filter-button");
      if (!closest) return;

      openFilters(closest);
    }, false);

    if (searchControl) {
      $("input", searchControl).addEventListener('keyup', function(event) {
        if (event.code === 'Enter') {
          event.preventDefault();
          filterItems();
        }
      });
      $("button", searchControl).addEventListener("click", this.filterItems);
    }

    if (loadMoreSection && items.length > displayAmount) {
      loadMoreSection.classList.remove("hidden");
      $("button", loadMoreSection).addEventListener("click", this.loadMore);
    }

    //Catch browsers retained radio button selection on back button hit
    setTimeout(() => {
      filterItems();
    }, 200);
  }

  filterItems() {
    let items = this.items;

    this.closeFilters();

    items.forEach(item => {
      item.classList.remove("filtered");
      item.classList.remove("revealed");
    })

    const unspecifiedFilters = [];

    this.filterSelectors.forEach(selector => {
      const filter = $(selector, this.component);
      let value = filter.value;
      let name = filter.name;

      if (value !== 'on') {
        items.forEach(item => {
          let content = item.getAttribute(`data-${name}`);
          if (content.indexOf(value) === -1) {
            if ("Engineering,Procurement,Construction".indexOf(value) !== -1) {
              if (content.indexOf("Integrated EPC") === -1) {
                item.classList.add("filtered");
              }
            } else {
              item.classList.add("filtered");
            }
          }
        })
      } else {
        unspecifiedFilters.push(filter);
      }
    })

    if (this.searchControl) {
      let searchInput = $("input", this.searchControl).value.toLowerCase();

      if (searchInput !== '') {
        items.forEach(item => {
          let content = item.textContent.toLowerCase();
          if (content.indexOf(searchInput) === -1) {
            item.classList.add("filtered");
          }
        })
      }
    }

    let remainingItems = Array.from($$("div[itemprop='itemListElement']:not(.filtered)", this.component.parentNode));

    // Hides options in other filters which would not yield results.
    unspecifiedFilters.forEach((filter) => {
      const name = filter.name;
      const options = filter.closest(`fieldset[name=${name}]`).querySelectorAll(`input[type='radio'][value]`);
      options.forEach(({ value, parentElement }) => {
        const items = remainingItems.filter((item) => item.getAttribute(`data-${name}`) === value);
        parentElement.toggleAttribute("hidden", !items.length);
      });
    });

    if (remainingItems.length === 0) {
      this.emptyMessage.classList.remove("hidden");
    } else {
      this.emptyMessage.classList.add("hidden");
    }

    if (this.loadMoreSection) {
      remainingItems = Array.prototype.slice.call(remainingItems);
      this.revealItems(remainingItems);
    }
  }

  initLoadMore(targetedItems) {
    this.items.forEach(item => {
      item.classList.remove("hidden");
    })

    if (targetedItems.length > this.displayAmount) {

      targetedItems.forEach((item, index) => {
        if (index >= this.displayAmount) {
          item.classList.add("hidden");
        }
      })

      this.loadMoreSection.classList.remove("hidden");
    } else {
      this.loadMoreSection.classList.add("hidden");
    }
  }

  loadMore() {
    let hiddenNotFiltered = $$("div[itemprop='itemListElement']:not(.revealed):not(.filtered)", this.component.parentNode);
    let items = Array.prototype.slice.call(hiddenNotFiltered);
    this.revealItems(items);
  }

  revealItems(items) {
    let slicedItems = items.slice(0, this.displayAmount);

    slicedItems.forEach(item => {
      item.classList.add("revealed");
    })

    if (items.length <= this.displayAmount) {
      this.loadMoreSection.classList.add("hidden");
    } else {
      this.loadMoreSection.classList.remove("hidden");
    }
  }

  openFilters(button) {
    let fieldset = button.nextElementSibling;;
    let openedFilters = $$(".filter-control fieldset.block");
    let opened = fieldset.classList.contains("block");

    openedFilters.forEach(filter => {
      filter.classList.remove("block");
      filter.classList.add("hidden");
      filter.previousElementSibling.setAttribute("aria-expanded","false");
    })

    if (!opened) {
      fieldset.classList.add("block");
      fieldset.classList.remove("hidden");
      button.setAttribute("aria-expanded","true");
    }
  }

  closeFilters() {
    let openedFilters = $$(".filter-control fieldset.block");

    openedFilters.forEach(filter => {
      filter.classList.remove("block");
      filter.classList.add("hidden");
      filter.previousElementSibling.setAttribute("aria-expanded","false");
    })
  }
}

const filterForms = document.querySelectorAll(".filters");
for (let i = 0, count = filterForms.length; i < count; i++) {
  new FilterList(filterForms[i], {

  });
}

export default FilterList;
